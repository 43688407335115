
import { Component, Prop, Vue } from 'vue-property-decorator'
import Invoice from '../types/Invoice.ts'

@Component
export default class InvoiceUpload extends Vue {
  @Prop({ type: Invoice })
  journeyTotal!: { invoiceReference: ''; totalGross: 0; totalNet: 0; totalTax: 0 }
  @Prop({ type: Invoice })
  invoiceTotal!: { invoiceReference: ''; totalGross: 0; totalNet: 0; totalTax: 0 }
  @Prop({ type: String })
  fileName!: ''
  @Prop({ type: String, default: 'GBP' })
  currency!: ''
  @Prop({ type: Boolean })
  loading!: false

  // computed
  get totalNetMatch() {
    let invoiceNet = this.invoiceTotal.totalNet ? this.invoiceTotal.totalNet : 0
    return this.journeyTotal.totalNet.toFixed(2) === invoiceNet.toFixed(2)
  }
  get invoiceAccepted() {
    return this.totalNetMatch
  }

  // methods
  match(journeyValue: number | string, invoiceValue: number | string) {
    return journeyValue == invoiceValue
  }
  download() {
    this.$emit('download')
  }
}
