
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RegisterDetailsForInvoice extends Vue {
  // props // ***
  @Prop({ default: false, type: Boolean })
  value!: boolean
  @Prop({ type: Boolean })
  isAddressRegistered!: boolean
  @Prop({ type: Boolean })
  isFinancialDetailsRegistered!: boolean

  cancel() {
    this.$emit('cancel')
  }
}
