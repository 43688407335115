
import api from '@/utilities/api'
import { Component, Prop, Vue } from 'vue-property-decorator'
import DatePicker from '@/components/forms/DatePicker.vue'
import { DateTime } from 'luxon'
import { isRequired } from '@/utilities/validations'
import { downloadDirectToBrowser } from '@/utilities/functions'

@Component({
  components: {
    'c-date-picker': DatePicker,
  },
})
export default class GenerateInvoicePdfDialog extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  value!: boolean
  @Prop({ default: () => [] })
  selectedJourneys!: any[]
  @Prop({ type: String, default: '' })
  organisationId!: string
  @Prop({ type: String })
  currency!: string
  @Prop({ default: () => [] })
  addresses!: any[]

  // data // ***
  loading = false
  invoiceDate: DateTime = DateTime.now()
  supplierInvoiceNumber = ''
  selectedAddress: any = {
    text: '',
    orginalItem: null,
  }

  // computed // ***
  get rules(): Record<string, any> {
    return {
      supplierInvoiceNumber: [isRequired],
      selectedAddress: [isRequired],
    }
  }
  get formattedAddresses(): { text: string; orginalItem: any }[] {
    return this.addresses.map(item => ({
      text: `${item.address1}, ${item.town}, ${item.postCode}`,
      orginalItem: item,
    }))
  }

  // method // ***
  async save() {
    const valid = await this.validateForm()
    if (!valid) return
    this.loading = true
    let payload = {
      supplierInvoiceNumber: this.supplierInvoiceNumber,
      invoiceDate: this.invoiceDate,
      journeys: this.selectedJourneys,
      organisationId: this.organisationId,
      address: this.selectedAddress.orginalItem ?? this.addresses[0],
      currency: this.currency,
    }
    const url = `supplier/invoice/generate-invoice-pdf`
    const options = { method: 'POST' }
    const axiosOptions = { data: payload, responseType: 'blob' }
    await api(url, options, axiosOptions)
      .then(res => {
        if (res && res.data) {
          downloadDirectToBrowser(res.data, `invoice-${this.supplierInvoiceNumber}.pdf`)
          this.cancel()
        } else {
          console.error('Invalid response from the server')
        }
      })
      .finally(() => (this.loading = false))
  }
  cancel() {
    this.$emit('cancel')
    this.reset()
  }
  reset() {
    this.invoiceDate = DateTime.now()
    this.supplierInvoiceNumber = ''
    this.selectedAddress = {
      text: '',
      orginalItem: {},
    }
    const refs: Record<string, any> = this.$refs
    refs.form.resetValidation()
  }
  async validateForm(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    return await refs.form.validate()
  }
}
