
import api from '@/utilities/api'
import { DEFAULT_DOCUMENT_EXPIRY_DAYS } from '@/utilities/constants'
import { title } from '@/utilities/filters'
import { isFuture, isRequired } from '@/utilities/validations'
import { DateTime } from 'luxon'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class UploadDocumentModal extends Vue {
  // props // ***
  @Prop({ required: true, type: Array })
  documentTypes!: Record<string, any>[]
  @Prop({ required: true, type: String })
  url!: string
  @Prop({ type: Boolean })
  value!: boolean

  // data // ***
  date: DateTime = DateTime.now().plus({ days: DEFAULT_DOCUMENT_EXPIRY_DAYS })
  file: Record<string, any> | null = null
  reference = ''
  selectedType: Record<string, any> | null = null

  // computed // ***
  get allowedDates() {
    return (s: string): boolean => isFuture(s) === true
  }
  get datePickerRules() {
    return [(v: DateTime) => isFuture(v.toISO())]
  }
  get fileErrorMessages(): string | undefined {
    const { validationErrors: ve } = this
    if (!ve?.length) return undefined
    else return ve[0]?.messages?.join(' | ') || 'An error occurred on the server.'
  }
  get expiryDateRequired(): boolean {
    return !!this.selectedType?.requiresExpiryDate
  }
  get loading(): boolean {
    const { url } = this
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get payload(): Record<string, any> {
    const { date, file: invoice, reference } = this
    return {
      expiryDate: date.toISO(),
      documentType: this.selectedType?.id,
      invoice,
      reference,
      organisationId: this.supplier?.id,
    }
  }
  get rules(): Record<string, any> {
    return {
      fileInput: [isRequired],
      reference: [isRequired],
    }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get title() {
    return this.selectedType
      ? this.$t('ui.uploadX', { x: this.selectedType?.description })
      : title(String(this.$t('cmac.selectDocumentType')))
  }
  get validationErrors(): Record<string, any> {
    return this.$store.getters['core/validationErrors']
  }

  // watch // ***
  @Watch('documentTypes')
  onDocumentTypesChanged() {
    this.setInitialDocumentType()
  }
  @Watch('value')
  onValueChanged(n: boolean) {
    if (!n) this.reset()
  }

  // created // ***
  created() {
    this.setInitialDocumentType()
  }

  // methods // ***
  goBack() {
    this.reset()
  }
  onClose() {
    this.reset()
    this.$emit('input', false)
  }
  async onSubmit() {
    this.resetValidation()
    const valid = await this.validateForm()
    if (!valid) return
    let formData = new FormData()
    formData.append('expiryDate', this.payload.expiryDate)
    formData.append('reference', this.payload.reference)
    formData.append('documentTypeId', this.payload.documentType)
    formData.append('organisationId', this.payload.organisationId)
    formData.append('invoice', this.payload.invoice)
    const url = this.url
    const options = { headers: { 'Content-Type': 'multipart/form-data' }, method: 'POST' }
    const axiosOptions = { data: formData }
    api(url, options, axiosOptions)
      .then(() => this.onUploadSuccess())
      .catch((e: Record<string, any>) => console.error(e))
  }
  onTypeSelected(e: Record<string, any>) {
    this.selectedType = e || null
  }
  onUploadSuccess() {
    this.$emit('uploaded')
    this.onClose()
  }
  reset() {
    this.setInitialValues()
    this.resetValidation()
  }
  resetValidation() {
    this.$store.commit('core/resetErrors')
  }
  setInitialDocumentType() {
    if (this.documentTypes.length === 1) this.selectedType = this.documentTypes[0]
    else this.selectedType = null
  }
  setInitialValues() {
    this.date = DateTime.now().plus({ days: DEFAULT_DOCUMENT_EXPIRY_DAYS })
    this.file = null
    this.reference = ''
    this.setInitialDocumentType()
  }
  async validateForm(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    return await refs.form.validate()
  }
}
