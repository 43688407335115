const items = (context: Record<string, any>): Record<string, any>[] =>
  context.invoices.map(
    (v: Record<string, any>): Record<string, any> => ({
      ...v,
      itemSpecificConfig: {
        class: context.deletionRequested(v) ? 'deletionRequested' : '',
        actions: {
          vueComponent: {
            props: {
              actions:
                context.actions?.map((a: Record<string, any>) => ({
                  ...a,
                  disabled: context.actionIsDisabled(v, a),
                  loading: context.actionIsLoading(v, a),
                  title: context.getActionTitle(v, a),
                })) || [],
            },
          },
        },
      },
    })
  )

export default items
