
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { DEFAULT_TABLE_OPTIONS } from '@/utilities/constants'
import cloneDeep from 'lodash/cloneDeep'
import api from '@/utilities/api'
import GeneratePdfDialog from '../GenerateInvoicePdfDialog'
import RegisterDetailsForInvoiceDialog from '../RegisterDetailsForInvoiceDialog'

const { now } = DateTime

@Component({
  components: {
    'c-generate-pdf-dialog': GeneratePdfDialog,
    'c-register-details-for-invoice-dialog': RegisterDetailsForInvoiceDialog,
  },
})
export default class InvoiceJourneySelector extends Vue {
  @Prop({ type: Boolean, default: false })
  error!: false
  @Prop({ type: Boolean, default: false })
  downloading!: false

  loadingCreatePdfInvoiceDialog = false
  loading = false
  searched = false
  filters = {
    dates: [now().plus({ days: -10 }), now()],
    cmacRef: '',
    externalRef: '',
  }
  pagination = {
    page: 1,
    total: 0,
  }
  headers: any[] = [
    { text: 'invoiceUpload.cmacRef', value: 'cmacRef' },
    { text: 'invoiceUpload.yourRef', value: 'externalId' },
    { text: 'invoiceUpload.departureDate', value: 'departureDateOffset' },
    { text: 'invoiceUpload.details', value: 'details' },
    { text: 'invoiceUpload.journey', value: 'journey' },
    { text: 'invoiceUpload.cancelled', value: 'cancelledText' },
    { text: 'invoiceUpload.journeyCost', value: 'costSourceNet' },
    { text: 'invoiceUpload.leadMilesCost', value: 'leadMilesCost' },
    { text: 'invoiceUpload.waitingTimeCost', value: 'waitingTimeCost' },
    { text: 'invoiceUpload.expensesCost', value: 'expensesCost' },
    { text: 'invoiceUpload.greenFeeCost', value: 'greenFeeCost' },
    { text: 'invoiceUpload.totalCost', value: 'totalCost' },
    { text: 'invoiceUpload.totalTax', value: 'totalTax' },
    { text: 'invoiceUpload.totalGross', value: 'totalGross' },
    { text: 'invoiceUpload.status', value: 'status' },
  ]
  options = cloneDeep(DEFAULT_TABLE_OPTIONS)
  journeys: any[] = []
  selectedJourneys: any[] = []
  currency = 'GBP'
  journeyTotal: any = {
    totalGross: 0,
    totalNet: 0,
    totalTax: 0,
  }
  showInvoiceDialog = false
  showRegisterDetailsDialog = false
  dataForInvoiceCreation: any = {
    isFinancialDetailsRegistered: false,
    addresses: [],
  }

  // watch // ***
  @Watch('doSelectedJourneysRequireSeparateInvoices')
    onDoSelectedJourneysRequireSeparateInvoicesChange(e: boolean) {
      this.$emit('separate-invoices-required', e)
  }

  // computed
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get organisationId(): string {
    return String(this.supplier?.id)
  }
  get computedHeaders() {
    return this.headers.map(header => ({
      ...header,
      text: this.$t(header.text)
    }))
  }
  get computedJourneys() {
    let journeys = [...this.journeys]
    journeys.forEach(j => {
      j.isSelectable = !j.supplierInvoiceNumberId && !j.supplierCostConfirmationDisputeId
    })
    return journeys
  }
  get isAddressRegistered(): boolean {
    return this.dataForInvoiceCreation.addresses.length > 0
  }
  get isMobile() {
    if (['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name)) {
      return true
    }
    return false
  }
  get isSmallMobile() {
    if (['xs', 'sm'].includes(this.$vuetify.breakpoint.name)) {
      return true
    }
    return false
  }
  get doSelectedJourneysRequireSeparateInvoices() {
    const hasTenOrMore = this.selectedJourneys.some(x => x.capacity >= 10)
    const hasLessThanTen = this.selectedJourneys.some(x => x.capacity < 10)
    return hasTenOrMore && hasLessThanTen
  }

  // methods
  async createPdfInvoice() {
    this.loadingCreatePdfInvoiceDialog = true
    await this.fetchAdditionalDataForInvoiceCreation()
    this.loadingCreatePdfInvoiceDialog = false

    if (
      this.dataForInvoiceCreation.isFinancialDetailsRegistered === true &&
      this.isAddressRegistered
    ) {
      this.showInvoiceDialog = true
    } else {
      this.showRegisterDetailsDialog = true
    }
  }
  async searchJourneys() {
    this.selectedJourneys = []
    this.loading = true
    var request = {
      organisationId: this.organisationId,
      dateFrom: this.filters.dates[0].toISO(),
      dateTo: this.filters.dates[1].toISO(),
      externalId: this.filters.externalRef,
      cmacRef: this.filters.cmacRef,
    }
    await api('supplier/invoice/invoiceable-journeys', { method: 'POST' }, { data: request })
      .then(response => {
        this.journeys = response.data.journeys
        this.currency = response.data.currency
        this.$emit('currency', this.currency)
      })
      .finally(() => {
        this.loading = false
        this.searched = true
      })
  }
  async fetchAdditionalDataForInvoiceCreation() {
    try {
      const response = await api(
        `supplier/invoice/supplier-details?organisationId=${this.organisationId}`,
        { method: 'GET' },
        {}
      )
      const data = response.data
      if (data) {
        this.dataForInvoiceCreation = data
      }
    } catch (error) {
      console.error('Error occurred getting additional information about the supplier:', error)
    }
  }
  rowClicked(item: any) {
    let index = this.selectedJourneys.indexOf(item)
    if (index === -1) {
      this.selectedJourneys.push(item)
    } else {
      this.selectedJourneys.splice(index, 1)
    }
    this.journeySelectionUpdate()
  }
  async journeySelectionUpdate() {
    await this.$nextTick()
    await this.$nextTick()

    let net = this.selectedJourneys.reduce(
      (total, current) => total + current.costSourceNet + this.journeyExpenseNet(current),
      0
    )
    let tax = this.selectedJourneys.reduce(
      (total, current) => total + current.costSourceTax + this.journeyExpenseTax(current),
      0
    )

    this.journeyTotal.totalNet = net
    this.journeyTotal.totalTax = tax
    this.journeyTotal.totalGross = net + tax

    this.$nextTick(() => {
      this.$emit('journeys', this.selectedJourneys)
      this.$emit('total', this.journeyTotal)
    })
  }
  journeyExpenseNet(journey: any) {
    if (!journey.expenses) return 0
    return journey.expenses.reduce((total: any, current: any) => total + current.costSourceNet, 0)
  }
  journeyExpenseTax(journey: any) {
    if (!journey.expenses) return 0
    return journey.expenses.reduce((total: any, current: any) => total + current.costSourceTax, 0)
  }
  supplierChanged() {
    this.journeys = []
    this.selectedJourneys = []
    this.journeySelectionUpdate()
  }
  selectAllChanged(evt: any) {
    if (evt.value) {
      this.selectedJourneys = [...this.journeys]
    } else {
      this.selectedJourneys = []
    }
    this.journeySelectionUpdate()
  }
  onDatePickerClose() {
    this.searchJourneys()
  }
  getLeadMilesCost(item: any) {
    var expenses = item.expenses.filter((x: Record<string, any>) => x.expenseId === 143)
    return expenses.reduce((total: any, current: any) => total + current.costSourceNet, 0)
  }
  getWaitingTimeCost(item: any) {
    var expenses = item.expenses.filter((x: Record<string, any>) => x.expenseId === 6)
    return expenses.reduce((total: any, current: any) => total + current.costSourceNet, 0)
  }
  getExpensesCost(item: any) {
    var expenses = item.expenses.filter(
      (x: Record<string, any>) => x.expenseId != 143 && x.expenseId !== 6 && x.expenseId !== 138
    )
    return expenses.reduce((total: any, current: any) => total + current.costSourceNet, 0)
  }
  getGreenFeeCost(item: any) {
    var expenses = item.expenses.filter((x: Record<string, any>) => x.expenseId === 138)
    return expenses.reduce((total: any, current: any) => total + current.costSourceNet, 0)
  }
  getTotalNet(item: any) {
    return (
      item.costSourceNet +
      item.expenses.reduce((total: any, current: any) => total + current.costSourceNet, 0)
    )
  }
  getTotalTax(item: any) {
    return (
      item.costSourceTax +
      item.expenses.reduce((total: any, current: any) => total + current.costSourceTax, 0)
    )
  }
  getTotalGross(item: any) {
    return (
      item.costSourceNet +
      item.costSourceTax +
      item.expenses.reduce((total: any, current: any) => total + current.costSourceNet, 0) +
      item.expenses.reduce((total: any, current: any) => total + current.costSourceTax, 0)
    )
  }
  download() {
    this.$emit('download')
  }
}
