
import { Component, Vue } from 'vue-property-decorator'
import api from '@/utilities/api'
import { DateTime } from 'luxon'
import { downloadAjax } from '@/utilities/functions'
import { title } from '@/utilities/filters'

@Component({})
export default class InvoiceUploads extends Vue {
  // data
  loading = false
  headers: any[] = [
    { text: 'invoiceUpload.invoiceNo', value: 'id' },
    { text: 'invoiceUpload.reference', value: 'invoiceReference' },
    { text: 'invoiceUpload.uploadDate', value: 'createdDateUtc' },
    { text: 'invoiceUpload.invoiceDate', value: 'invoiceDate' },
    { text: 'invoiceUpload.total', value: 'gross' },
    { text: 'invoiceUpload.currency', value: 'currency' },
    { text: 'invoiceUpload.status', value: 'statusDescription' },
    { text: 'invoiceUpload.comments', value: 'rejectionReason' },
    { text: 'invoiceUpload.file', value: 'filename' },
    { value: 'download' },
  ]
  invoices: any[] = []
  currency = 'GBP'
  dates: DateTime[] = this.getDefaultDates()
  status = -1
  statuses = {
    items: [
      { text: title(String(this.$t('common.all'))), value: -1 },
      { text: title(String(this.$t('common.created'))), value: 0 },
      { text: title(String(this.$t('common.processed'))), value: 1 },
      { text: title(String(this.$t('invoiceUpload.paid'))), value: 2 },
      { text: title(String(this.$t('invoiceUpload.rejectedStatus'))), value: 3 },
    ],
  }

  mounted() {
    this.redirectIfDisabled()
    this.getInvoices()
  }

  // computed
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get organisationId(): string {
    return String(this.supplier?.id)
  }
  get computedHeaders() {
    return this.headers.map(header => {
      return {
        ...header,
        text: this.$t(header.text),
      }
    })
  }

  // methods
  async getInvoices() {
    let selectedStatuses = this.status === -1 ? [0, 1, 2, 3] : [this.status]
    let request = {
      organisationId: this.organisationId,
      dateFromUtc: this.dates[0].toISODate(),
      dateToUtc: this.dates.length > 1 ? this.dates[1].toISODate() : this.dates[0].toISODate(),
      invoiceStatus: selectedStatuses,
    }
    this.loading = true
    api(
      '/account-supplier-invoice-upload',
      { data: request, json: true, method: 'POST' },
      undefined
    )
      .then(response => {
        this.invoices = response.data.invoices
        this.currency = response.data.currency
      })
      .finally(() => {
        this.loading = false
      })
  }
  async downloadFile(item: any) {
    console.log(item)
    await downloadAjax(`OrganisationDocument/open/${item?.organisationDocumentId}`, item?.filename)
  }
  getDefaultDates() {
    let start = DateTime.now().plus({ days: -60 })
    let end = DateTime.now()
    return [start, end]
  }
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('invoiceUploads'))
      this.$router.push('/errors/404')
  }
  onDatePickerClose() {
    this.getInvoices()
  }
}
