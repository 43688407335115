import { getFormattedDate } from '@/utilities/org'
import i18n from '@/plugins/i18n'

const headers = (): Record<string, any>[] => [
  { text: i18n.t('common.filename'), value: 'filename', width: '15%' },
  { text: i18n.t('common.reference'), value: 'reference', width: '15%' },
  {
    renderFunction: (v: string) => (v ? getFormattedDate(v) : null),
    text: i18n.t('invoiceUpload.uploadDate'),
    value: 'createdDateUtc',
  },
  // { text: 'Name', value: 'filename', width: '15%' },
  { text: i18n.t('invoiceUpload.gross'), value: 'gross' },
  {
    renderFunction: (v: string) => (v ? getFormattedDate(v) : null),
    text: i18n.t('invoiceUpload.invoiceDate'),
    value: 'invoiceDate',
  },
  // {
  //   renderFunction: (v: string) => (v ? getFormattedDate(v) : null),
  //   text: 'Received',
  //   value: 'receivedDateUtc',
  // },
  {
    renderFunction: (v: string) => (v ? getFormattedDate(v) : null),
    text: i18n.t('common.processed'),
    value: 'processedDateUtc',
  },
  {
    renderFunction: (v: string) => (v ? getFormattedDate(v) : null),
    text: i18n.t('invoiceUpload.paid'),
    value: 'paymentDate',
  },
  // { text: 'Notes', value: 'notes', width: '30%' },
]

export default headers
