
import api from '@/utilities/api'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Invoice from './types/Invoice'
import InvoiceJourneySelector from './components/InvoiceJourneySelector'
import InvoiceUploadSummary from './components/InvoiceUploadSummary'
import { DateTime } from 'luxon'
import { downloadDirectToBrowser } from '@/utilities/functions'

@Component({
  components: {
    InvoiceUploadSummary,
    InvoiceJourneySelector,
  },
})
export default class InvoiceUpload extends Vue {
  step = 1
  analyzing = false
  uploading = false
  invoiceUploadError = false
  journeySelectionError = false
  analyseError = false
  journeys: any[] = []
  file: File = new File([], '')
  currency = 'GBP'
  invoiceNumberFieldName = ''
  totalNetAmountFieldName = ''
  isUniqueFileName = false
  containsInvalidCharacters = false
  invalidFilenameCharacters = ''
  validFileExtensions = ''
  journeyTotal: Invoice = {
    invoiceReference: '',
    totalGross: 0,
    totalNet: 0,
    totalTax: 0,
  }
  invoiceResult: Invoice = {
    invoiceReference: '',
    totalGross: 0,
    totalNet: 0,
    totalTax: 0,
  }
  internalValue = {
    invoiceReference: '',
    invoiceDate: DateTime.local(),
  }
  rules = {
    required: (v: any) => {
      return !!v || 'Required'
    },
  }
  loading = false
  isContinueDisabled = false

  // computed
  get supplier() {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get suppliers(): Record<string, any> {
    return this.$store.getters['suppliers/suppliers']
  }
  get totalNetMatch() {
    return (
      this.journeyTotal.totalNet.toFixed(2) ===
      (this.invoiceResult.totalNet && this.invoiceResult.totalNet.toFixed(2))
    )
  }
  get isSmallMobile() {
    if (['xs', 'sm'].includes(this.$vuetify.breakpoint.name)) {
      return true
    }
    return false
  }
  get buttonsSlotName() {
    return this.isSmallMobile ? 'footer' : ''
  }
  get features(): Record<string, any> {
    return this.$auth.features
  }

  //Watch
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.setFieldNames()
  }
  // methods
  async mounted() {
    await this.setFieldNames()
  }
  async setFieldNames() {
    this.invoiceNumberFieldName = ''
    this.totalNetAmountFieldName = ''
    let config = await this.getInvoiceUploadConfig()
    if (config) {
      if (config.invoiceNumberFieldName) {
        this.invoiceNumberFieldName = config.invoiceNumberFieldName
      }
      if (config.totalNetAmountFieldName) {
        this.totalNetAmountFieldName = config.totalNetAmountFieldName
      }
    }
  }
  journeySelectionUpdate(journeys: any[]) {
    this.journeys = journeys
    this.journeySelectionError = false
  }
  invoiceUpdate(total: Invoice) {
    this.journeyTotal = total
  }
  setCurrency(currency: string) {
    this.currency = currency
  }
  selectJourneys() {
    if (this.journeys.length > 0) {
      this.journeySelectionError = false
      this.step = 2
    } else {
      this.journeySelectionError = true
    }
  }
  uploadInvoice() {
    this.analyseError = false
    if (this.file !== null) {
      this.invoiceUploadError = false
      this.submitInvoiceForAnalysis()
      this.step = 3
    } else {
      this.invoiceUploadError = true
    }
  }
  submitInvoiceForAnalysis() {
    if (this.file === null) return

    this.analyzing = true

    let formData: FormData = new FormData()
    formData.append('file', this.file)
    formData.append('organisationId', this.supplier.id)
    formData.append('invoiceNumberFieldName', this.invoiceNumberFieldName)
    formData.append('totalNetAmountFieldName', this.totalNetAmountFieldName)

    api(
      'supplier/invoice/analyse-invoice',
      { data: formData, method: 'POST', formData: true },
      undefined
    )
      .then(response => {
        this.invoiceResult = {
          invoiceReference: response.data.invoiceReference.value,
          totalGross: response.data.totalGross.value,
          totalNet: response.data.totalNet.value,
          totalTax: response.data.totalTax.value,
        }
        this.internalValue.invoiceReference = response.data.invoiceReference.value
      })
      .catch(() => {
        this.step = 2
        this.analyseError = true
      })
      .finally(() => {
        this.analyzing = false
      })
  }
  upload() {
    if (!this.validateUploadForm()) return

    this.uploading = true

    let formData: FormData = new FormData()
    formData.append('file', this.file)
    formData.append('organisationId', this.supplier.id)
    formData.append('net', this.journeyTotal.totalNet.toString())
    formData.append('tax', this.journeyTotal.totalTax.toString())
    formData.append('gross', this.journeyTotal.totalGross.toString())
    formData.append('predictedInvoiceReference', this.invoiceResult.invoiceReference)
    formData.append('predictedNet', this.invoiceResult.totalNet?.toString() || '')
    formData.append('predictedTax', this.invoiceResult.totalTax?.toString() || '')
    formData.append('predictedGross', this.invoiceResult.totalGross?.toString() || '')
    formData.append('invoiceReference', this.internalValue.invoiceReference)
    formData.append('invoiceDate', this.internalValue.invoiceDate.toISODate())
    this.journeys.forEach(x => {
      formData.append('waypointJobEntryIds', x.id.toString())
    })

    api(
      'supplier/invoice/upload-invoice',
      { data: formData, method: 'POST', formData: true },
      undefined
    )
      .then(() => {
        this.redirectToIndex()
      })
      .finally(() => {
        this.uploading = false
      })
  }
  validateUploadForm() {
    return (this.$refs.uploadForm as Vue & { validate: () => boolean }).validate()
  }
  redirectToIndex() {
    this.$router.push({ name: 'InvoiceUploads' })
  }
  checkForUniqueFilename() {
    this.isUniqueFileName = true
    this.invalidFilenameCharacters = ''
    this.validFileExtensions = ''
    let payload = {
      organisationId: this.supplier.id,
      fileName: this.file.name,
    }
    api('supplier/invoice/check-isuniquefilename', { method: 'POST' }, { data: payload })
      .then(response => {
        this.isUniqueFileName = response.data.isUniqueFileName
        this.containsInvalidCharacters = response.data.containsInvalidCharacters
        this.invalidFilenameCharacters = response.data.invalidFilenameCharacters
        this.validFileExtensions = response.data.validFileExtensions
      })
      .catch(() => {
        this.step = 2
        this.isUniqueFileName = false
      })
  }
  async download(): Promise<void> {
    this.loading = true
    var data = {
      WaypointJobEntryIds: this.journeys.map(x => x.id),
      OrganisationId: this.supplier.id,
    }
    const res = await api(
      `supplier/invoiceupload/download`,
      { data: data, method: 'POST', json: true },
      { responseType: 'blob' }
    )
    if (res && res.data) downloadDirectToBrowser(res.data, 'export.xlsx')
    this.loading = false
  }
  handleSeparateInvoicesRequired(e: boolean) {
    this.isContinueDisabled = e
  }
  async getInvoiceUploadConfig(): Promise<Record<string, any>> {
    if (this.suppliers.length === 1) return this.features.invoiceUploads.configuration
    let config = await api(
      `supplier/invoice/uploads-config?organisationId=${this.supplier.id}`,
      {},
      {}
    ).then((x: any) => {
      return x.data
    })
    return config
  }
}
