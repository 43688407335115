
import { Component, Vue, Watch } from 'vue-property-decorator'
import RequestDeletionDialog from './RequestDeletionDialog'
import UploadInvoiceModal from './UploadInvoiceModal'
import { headers, items } from '@/pages/documents/helpers/invoices/index'
import { DOCUMENT_TYPES } from '@/utilities/constants'
import api from '@/utilities/api'
import { downloadAjax } from '@/utilities/functions'
import { DateTime } from 'luxon'
import { identifier } from '@/pages/documents/helpers/index'

const { INVOICE } = DOCUMENT_TYPES

interface RequestDeletionDialogInterface {
  item: Record<string, any> | null
  value: boolean
}

@Component({
  components: {
    'c-upload-invoice-modal': UploadInvoiceModal,
    'c-request-deletion-dialog': RequestDeletionDialog,
  },
})
export default class Invoices extends Vue {
  // data // ***
  dates: DateTime[] = [DateTime.now().minus({ months: 6 }), DateTime.now()]
  requestDeletionDialog: RequestDeletionDialogInterface = { item: null, value: false }
  uploadModalOpen = false
  values: Record<string, any> = { documentTypes: [INVOICE] }

  // computed // ***
  get actionHeader() {
    const { actions } = this
    return { vueComponent: { name: 'c-table-actions', props: { actions } } }
  }
  get actions() {
    return [
      {
        callback: (item: Record<string, any>) => this.onDownload(item),
        color: 'accent',
        icon: 'file_download',
        value: 'download',
      },
      {
        callback: (item: Record<string, any>) => this.onDelete(item),
        color: 'secondary',
        icon: 'delete',
        value: 'delete',
      },
    ]
  }
  get deleting(): boolean {
    const { delete: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get documentTypes() {
    return this.values.documentTypes.map((t: string) => ({
      attributes: 0,
      description: 'Invoice',
      id: t,
      requiresExpiryDate: false,
    }))
  }
  get features(): Record<string, any> {
    return this.$auth.features
  }
  get fetchingDocuments(): boolean {
    const { fetch: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get headers() {
    return headers()
  }
  get invoices() {
    return this.$store.getters['suppliers/invoices']
  }
  get items() {
    return items(this)
  }
  get loading(): boolean {
    return this.fetchingDocuments
  }
  get organisationId(): string {
    return String(this.supplier?.id)
  }
  get params() {
    const { dates, organisationId, values } = this
    const { documentTypes: documentTypeIds } = values
    const getDate = (d: DateTime): string => d.toISODate()
    return {
      documentTypeIds,
      from: getDate(dates[0]),
      to: dates.length > 1 ? getDate(dates[1]) : getDate(dates[0]),
      organisationId,
      ReturnAllResults: true,
    }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get uploading(): boolean {
    const { upload: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get urls(): Record<string, string> {
    return {
      delete: 'OrganisationDocument/RequestDelete',
      fetch: 'supplier/invoices',
      upload: 'supplier/invoice',
    }
  }

  // created // ***
  created() {
    this.redirectIfDisabled()
    this.onCreated()
  }
  async onCreated() {
    this.fetch()
  }

  // watch // ***
  @Watch('features')
  onFeaturesChanged(): void {
    this.redirectIfDisabled()
  }
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch()
  }

  // methods // ***
  actionIsDisabled(item: Record<string, any>, action: Record<string, any>): boolean {
    if (action.value === 'delete') return this.deleteDisabledForItem(item)
    else if (action.value === 'download') return this.downloadDisabledForItem(item)
    else return false
  }
  actionIsLoading(item: Record<string, any>, action: Record<string, any>): boolean {
    return !(item && action) // TODO: set this up once the download/delete endpoints are in
  }
  deleteDisabledForItem(item: Record<string, any>): boolean {
    return this.deletionRequested(item)
  }
  deletionRequested(item: Record<string, any>): boolean {
    const { attributes: a } = item
    if (!a || !a.length) return false
    else return a?.indexOf('DeletionRequested') !== -1
  }
  downloadDisabledForItem(item: Record<string, any>): boolean {
    return this.deletionRequested(item) || !item?.documentId
  }
  openRequestDeletionDialog(i: Record<string, any>) {
    this.requestDeletionDialog.item = i
    this.requestDeletionDialog.value = true
  }
  async fetch() {
    const { params: data, urls } = this
    const { fetch: url } = urls
    const options = { data, json: true, method: 'POST', url }
    await this.$store.dispatch('suppliers/fetchInvoices', options)
  }
  getActionTitle(item: Record<string, any>, action: Record<string, any>): string {
    const delDisabledMessage = 'Document deletion has been requested.'
    if (action.value === 'delete' && this.actionIsDisabled(item, action)) return delDisabledMessage
    else if (action.value === 'delete') return 'Delete'
    else if (action.value === 'download') return 'Download'
    else return ''
  }
  getDownloadUrl(i: Record<string, any>): string {
    return `OrganisationDocument/open/${i?.documentId}`
  }
  async onDownload(i: Record<string, any>) {
    await downloadAjax(this.getDownloadUrl(i), i?.filename)
  }
  onDelete(i: Record<string, any>) {
    this.openRequestDeletionDialog(i)
  }
  onRequestDeletionDialogCancel(): void {
    this.resetRequestDeletionDialog()
  }
  onRequestDeletionDialogConfirm(i: Record<string, any>): void {
    this.requestDeletion(i)
  }
  onRequestDeletionDialogInput(e: Record<string, any>): void {
    if (!e) this.resetRequestDeletionDialog()
  }
  onSearch(): void {
    this.fetch()
  }
  onUpload(): void {
    this.fetch()
  }
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('invoiceManagement'))
      this.$router.push('/errors/404')
  }
  requestDeletion(item: Record<string, any>) {
    const { delete: url } = this.urls
    const message = `A request to delete the document with ${identifier(item)} has been sent.`
    const data = { DocumentId: item?.documentId, OrganisationId: this.supplier?.id }
    api(url, { data, json: true, method: 'POST' }, undefined).then(() => {
      this.$store.dispatch('core/launchSnackbar', { color: 'success', message })
      this.resetRequestDeletionDialog()
      this.fetch()
    })
  }
  resetRequestDeletionDialog() {
    this.requestDeletionDialog.value = false
    this.requestDeletionDialog.item = null
  }
}
